/*
* Element: Headers
*/

/*Setting document variables*/
:root{
    --heading-page-color-primary: var(--color-black);
    --pageheader-header-color: var(--color-white);
}

.heading-page {
    font-size: 45px;
    color: var(--heading-page-color-primary);
    font-weight: 800;
}
