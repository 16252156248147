/*
 * Module: Box
 */

:root{
    --box-background-color: var(--color-white);
    --box-border-radius: 4px;
    --box-padding: 20px;
    --box-paragraph-spacing-top: 15px;
    --box-shadow: 2px 5px 10px 0 rgba(0,0,0, .2);
}

.box {
    box-shadow: var(--box-shadow);

    &__inner {
        border-radius: var(--box-border-radius);
        background-color: var(--box-background-color);
    }

    &__image {
        width: 100%;
    }

    &__content {
        padding: var(--box-padding);
    }
    
    p + p {
        margin-top: var(--box-paragraph-spacing-top);
    }  
}