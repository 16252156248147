/**
 * Base
 */
 :root{
    --site-background-color: var(--site-background-color, #fff);
    --base-section-margin: 60px;
 }

/* Box sizing */
* {
    box-sizing: border-box;

    &:before,
    &:after {box-sizing: border-box;}
}


/* Body */
body {
    background-color: var(--site-background-color);
    font-family: var(--base-font-family);
    font-weight: var(--base-font-weight);
    font-size: var(--base-font-size);
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: var(--color-text);
    line-height: 1.5;
    overflow-x: hidden;
    position: relative;
    width: 100%;
    margin: 0;
    padding: 0;

    @media (--viewport-lg-min) {font-size: 18px;}
}


/* Links */
a[href] {
    color: var(--color-spearmint);
    text-decoration: underline;
    text-decoration-skip: ink;

    &:hover,
    &:focus {
        color: var(--color-spearmint);
        text-decoration: none;
    }
}

/* Horizontal ruler */
hr {
    border: none;
    border-bottom: 1px solid var(--color-gray);
    margin: 30px 0;
}


/* Image handling */
figure {
    margin: 0;
    background-size: auto 100%;
    background-position: center center;
    background-repeat: no-repeat;

    img {
        max-width: 100%;
        width: 100%;
        height: auto;
        display: block;
        margin: 0 auto;
        transition: opacity 0.2s;
    }

    &.contain-width img { width: auto; }
}

code {
    background-color: rgba(0, 0, 0, .5);
    border-radius: 8px;
    margin-left: 4px;
    margin-right: 4px;
    padding: 8px 10px 10px;
    word-break: break-all;
}

section + section {
    margin-top: var(--base-section-margin);
}
