:root{
    --pageheader-dots-background-color: var(--color-blackberry);
    --pageheader-dots-background-gradient: radial-gradient(rgba(255, 255, 255, 0.15) 1px, transparent 1px), transparent;
    --pageheader-dots-background-size: 40px 40px;
    --pageheader-dots-height: 100vh;
    --pageheader-dots-width: 100%;
    --pageheader-dots-padding: 10%;
    --pageheader-dots-padding-top: 20%;
    --pageheader-dots-padding-right: 10%;
    --pageheader-dots-padding-bottom: 10%;
    --pageheader-dots-padding-left: 10%;
}

.pageheader-dots {
    background-color: var(--pageheader-dots-background-color);
    width: var(--pageheader-dots-width);
    min-height: var(--pageheader-dots-height);

    &__back {
        color: var(--color-spearmint);
        left: 20px;
        position: absolute;
        top: 20px;
    }

    &__dots {
        top: 0;
        z-index: 1;
        width: var(--pageheader-dots-width);
        background: var(--pageheader-dots-background-gradient);
        background-size: var(--pageheader-dots-background-size);
        min-height: var(--pageheader-dots-height);
    }

    &__wrapper {
        min-height: var(--pageheader-dots-height);
        width: 100%;
        display: flex;
        align-items: center;
        padding-top: var(--pageheader-dots-padding-top);
        padding-right: var(--pageheader-dots-padding-right);
        padding-bottom: var(--pageheader-dots-padding-bottom);
        padding-left: var(--pageheader-dots-padding-left);

        @media (--viewport-sm-min) {
            padding: var(--pageheader-dots-padding);
        }
    }

    &__container {
        color: var(--color-white);
        font-family: var(--heading-font-family);
        flex: 1 1 auto;
        width: var(--pageheader-dots-width);
    }

    &__top-heading {
        font-size: 22px;
        margin: 0px;
        margin-bottom: 8px;

    }

    &__main-heading {
        font-weight: 800;
        font-size: 35px;
        line-height: 42px;
        margin: 0px;

        @media (--viewport-sm-min) {
            font-size: 65px;
            line-height: 82px;
        }
    }

    &__main-heading-shadow {
        z-index: -1;
        font-weight: 800;
        font-size: 35px;
        line-height: 42px;
        margin: 20px;
        position: relative;
        color: rgba(000, 000, 000, 0.2);

        @media (--viewport-sm-min) {
            font-size: 65px;
            line-height: 82px;
        }
    }

    &__sub-heading {
        font-size: 18px;
        font-weight: 300;
        line-height: 40px;
        margin-top: 30px;
    }

    &__button {
        margin-top: 50px;
    }
}
