/**
 * Fonts
 */

/* Don't do like this!!! */
@import url('https://fonts.googleapis.com/css?family=Lato');

/* Do like this: */
@font-face {
  font-family: 'Lato';
  font-style: normal;
  font-weight: 900;
  src: local('Lato Black'), local('Lato-Black'),
      url('/dist/fonts/lato-bold.woff2') format('woff2'),
      url('/dist/fonts/lato-bold.woff') format('woff')
}