/*
* Element: paragraphs
*/

/*Setting document variables*/
:root{
    --pageheader-color: var(--color-white);
}

.paragraph + .paragraph {
    margin-top: 20px;
}

