/*
 * Element: Link Buttons
 */

/*Setting document variables*/
:root{
    --link-button-color-primary: var(--color-red);
    --link-button-color-hover: var(--color-white);
    --link-button-color-white: var(--color-white);
    --link-button-color-text: var(--color-text);
    --link-button-base-font-family: var(--base-font-family);
}


/* Default button*/
.link-button,
.link-button--bordered,
.link-button--ghost {
    background-image: none; /* Reset unusual Firefox-on-Android default style; see https://github.com/necolas/normalize.css/issues/214*/
    border-radius: 4px;
    cursor: pointer;
    display: block;
    font-weight: 600;
    font-family: var(--link-button-base-font-family);
    font-size: 20px;
    line-height: 50px;
    padding: 0 30px;
    text-align: center;
    text-decoration: none;
    transition: background .3s, color .3s, border-color .3s;

    &[disabled],
    [disabled] & { cursor: not-allowed; }

    @media (--viewport-md-min) {
        display: inline-block;
    }
}

/* Add spacing between buttons if they are next to each other */
.link-button + .link-button,
.link-button--ghost + .link-button--ghost,
.link-button + .link-button--ghost,
.link-button--ghost + .link-button {
    margin-top: 20px;

    @media (--viewport-md-min) {
        margin-left: 20px;
    }
}

.link-button,
.link-button--bordered {
    background-color: var(--link-button-color-primary);
    color: var(--link-button-color-white);
    border: 1px solid transparent;

    &[href] {
        color: var(--link-button-color-white);
        text-decoration: none;
    }

    &[href]:hover,
    &[href]:focus {
        color: var(--link-button-color-primary);
        background: var(--link-button-color-hover);
        text-decoration: none;
        outline: none;
    }
}

.link-button--bordered {
    border: 2px solid var(--link-button-color-primary);
}

.link-button--ghost {
    background-color: transparent;
    color: var(--link-button-color-primary);
    border: 1px solid var(--link-button-color-primary);

    &[href] {
        color: var(--link-button-color-primary);
        text-decoration: none;
    }

    &[href]:hover,
    &[href]:focus {
        color: var(--link-button-color-primary);
        background: var(--link-button-color-hover);
        text-decoration: none;
        outline: none;
    }
}
