/*
 * Module: Box Image
 */

:root{
    --box-background-color: var(--color-black);
    --box-border-radius: 4px;
    --box-content-opacity: 85%;
    --box-min-height: 300px;
    --box-padding: 20px;
    --box-paragraph-spacing-top: 15px;
    --box-shadow: 2px 5px 15px 0 rgba(0,0,0, .15);
}

.box-image {
    color: var(--color-white);
    position: relative;
    width: 100%;
    box-shadow: var(--box-shadow);

    &__inner {
        position: relative;
        background-repeat: no-repeat;
        background-size: cover;
        min-height: var(--box-min-height);
        border-radius: var(--box-border-radius);
    }

    &__heading {
        font-size: 30px;
        margin-top: 0;
    }

    &__content {
        background-color: color(var(--box-background-color) alpha(var(--box-content-opacity)));
        border-radius: 0 0 var(--box-border-radius) var(--box-border-radius);
        bottom: 0;
        left: 0;
        padding: var(--box-padding);
        position: absolute;
        right: 0;
    }

    &__heading + p,
    p + p {
        margin-top: var(--box-paragraph-spacing-top);
    }
}
